import { Box, Flex, Grid, Text } from "@storyofams/react-ui";

import { Button } from "~components/common/Button";
import { ProductCard } from "~components/product/components";

interface ResultsProps {
  searchResult: {
    hits: any[];
    hasNextPage: boolean;
    total?: number;
  };
  isCollection?: boolean;
}

export const ProductResults = ({
  searchResult,
  isCollection = false,
}: ResultsProps) => {
  if (!searchResult?.hits && !searchResult?.hits?.length) {
    return null;
  }
  return (
    <>
      <Box display={isCollection ? "none" : "block"}>
        <Text
          font-family="DINPro,sans-serif"
          fontWeight="bold"
          lineHeight={0.9}
          verticalAlign="bottom"
          fontSize={3.5}
          pt={"12px"}
        >
          {`Producten (${searchResult.total})`}
        </Text>
      </Box>

      <Grid
        width="100%"
        rowGap={[5]}
        columnGap={[1.5]}
        rowSize={[2]}
        display={{ md: "none" }}
      >
        {searchResult.hits.map((product, index) => (
          <Box width={["48%", "23%"]} key={product?.id}>
            <ProductCard {...product} index={index} fromSearch />
          </Box>
        ))}
      </Grid>
      <Grid
        width="100%"
        rowGap={[5]}
        columnGap={[3]}
        rowSize={[4]}
        display={["none", "none", "grid"]}
      >
        {searchResult.hits.map((product, index) => (
          <Box width={["48%", "23%"]} key={product?.id}>
            <ProductCard {...product} index={index} fromSearch />
          </Box>
        ))}
      </Grid>
    </>
  );
};
