import { css, Flex, Select } from "@storyofams/react-ui";
import { useRouter } from "next/router";
import { components } from "react-select";

const messages = {
  popular: "Populariteit",
  priceAsc: "Prijs: laag - hoog",
  priceDesc: "Prijs: hoog - laag",
  nameAsc: "Naam: A-Z",
  nameDesc: "Naam: Z-A",
  new: "Nieuw - Oud",
  sortBy: "Sorteren",
};

export const sortOptions = [
  {
    value: "RELEVANCE",
    label: messages.popular,
  },
  {
    value: "PRICE",
    label: messages.priceAsc,
  },
  {
    value: "PRICE",
    label: messages.priceDesc,
  },
];

export const sortCollectionOptions = [
  {
    value: "BEST_SELLING",
    label: messages.popular,
  },
  {
    value: "PRICE",
    label: messages.priceAsc,
  },
  {
    value: "PRICE",
    label: messages.priceDesc,
  },
  {
    value: "TITLE",
    label: messages.nameAsc,
  },
  {
    value: "TITLE",
    label: messages.nameDesc,
  },
  {
    value: "CREATED",
    label: messages.new,
  },
];

export const SortBy = () => {
  const router = useRouter();
  const isCollectionPage =
    router?.route === "/collections/[handle]" || router?.route === "/products";
  const handleSortChange = (sortIdx: string) => {
    const route = {
      query: {
        ...router.query,
        sorting: isCollectionPage
          ? sortCollectionOptions[sortIdx].value
          : sortOptions[sortIdx].value,
        reverse: sortIdx === "2" || sortIdx === "4" ? "true" : "false",
      },
    };
    router.push(route, undefined, { shallow: true });
  };
  const options = isCollectionPage
    ? sortCollectionOptions.map(({ label }, idx) => ({
        label,
        value: `${idx}`,
      }))
    : sortOptions.map(({ label }, idx) => ({
        label,
        value: `${idx}`,
      }));
  return (
    <Flex alignItems="center">
      <Select
        width={"100%"}
        zIndex={5}
        isSearchable={false}
        isDisabled={false}
        onChange={({ value }) => handleSortChange(value)}
        defaultValue={sortOptions[0]}
        options={options}
        disableShadow
        components={{
          Control: (props) => (
            <components.Control {...props}>{props.children}</components.Control>
          ),
        }}
        minWidth={"183px"}
        css={css({
          "&.react-select": {
            fontSize: 2,
          },
          "& .react-select__control": {
            cursor: "pointer",
            minHeight: "38px",
            bg: "inherit",
            borderColor: "grey200",
            pl: "23px",
            borderRadius: "md",
            svg: {
              fill: "grey800",
              stroke: "grey800",
            },
          },
          "& .react-select__option": {
            fontSize: 2,
            py: 0.5,
            "&:hover": {
              cursor: "pointer",
            },
          },
          "& .react-select__option--is-selected": {
            bg: "grey400",
          },
          "& .react-select__value-container": {
            pl: 1.5,
          },

          "& .react-select__indicator": {
            color: "grey800",
          },
        })}
      />
    </Flex>
  );
};
