import React, { forwardRef } from "react";
import { CustomCheckbox, CustomCheckboxProps } from "@reach/checkbox";
import { Icon, InputWrapperProps } from "@storyofams/react-ui";
import { pick, omit } from "@styled-system/props";
import { useId } from "react-id-generator";
import styled from "styled-components";

import { Checkmark } from "../../common/Icons";
import { InputWrapper } from "../Forms";
import { Text } from "../Text";

const StyledCheckbox = styled(CustomCheckbox)`
  appearance: none;
  background-color: transparent;
  padding: 0;
  margin-right: 12px;
  border: none;

  position: relative;
  display: inline-flex;
  vertical-align: middle;

  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;

  flex-shrink: 0;

  .checkmark {
    width: 100%;
    min-width: 24px;
    height: 100%;

    background: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.radii.md};
    border: 1px solid ${({ theme }) => theme.colors.grey800};

    transition: border-color 0.18s ease-in-out, background 0.18s ease-in-out;

    svg {
      color: ${({ theme }) => theme.colors.white};
      display: none;
    }
  }

  &[data-reach-custom-checkbox] {
    input {
      appearance: none;
      outline: none;

      display: flex;
      align-items: center;
      position: relative;

      width: 0;
      height: 0;

      cursor: pointer;

      &:not([disabled]) {
        cursor: pointer;

        &:hover + .checkmark,
        &:focus + .checkmark {
          outline: none;
          background: ${({ theme }) => theme.colors.grey100};
        }

        &:checked + .checkmark {
          background: ${({ theme }) => theme.colors.grey800};
          border-color: ${({ theme }) => theme.colors.grey800};

          svg {
            display: block;
          }
        }
      }
    }
  }

  ${(props) => props.css}
`;

type CheckboxProps = {
  id?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: any;
} & InputWrapperProps;

export const Checkbox = forwardRef<CustomCheckboxProps, CheckboxProps>(
  (
    {
      label,
      status = false,
      statusMessage = false,
      error,
      id: givenId,
      disabled,
      ...rest
    },
    ref
  ) => {
    const autoId = useId();
    const id = givenId || `checkbox-${autoId}`;

    const checkboxBody = (
      <StyledCheckbox
        id={id}
        ref={ref}
        mr={1.5}
        disabled={disabled}
        {...omit(rest)}
      >
        <Icon className="checkmark" icon={Checkmark} fontSize="17px" />
      </StyledCheckbox>
    );

    return (
      <InputWrapper
        status={status}
        statusMessage={statusMessage}
        error={error}
        {...pick(rest)}
      >
        {label ? (
          <Text
            as="label"
            variant="label"
            fontSize={2}
            htmlFor={id}
            opacity={disabled ? 0.6 : 1}
            cursor={disabled ? "not-allowed" : "pointer"}
            color="grey900"
            textAlign="left"
          >
            {checkboxBody}
            {label}
          </Text>
        ) : (
          checkboxBody
        )}
      </InputWrapper>
    );
  }
);
