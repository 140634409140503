import React, { useEffect } from "react";
import { Spinner } from "@storyofams/react-ui";
import hoistNonReactStatics from "hoist-non-react-statics";
import { useRouter } from "next/router";

import { Seo } from "../components";
import { useShopify } from "../context";
import { getDisplayName } from "../lib/getDisplayName";
import { generateCode } from "./customerApiHelpers";

export function withAuth(Component: any) {
  const AuthComponent = (props) => {
    const { isReady, query } = useRouter();
    const { customerApiToken, customerIsLoading, customerStorefrontApiToken } =
      useShopify();
    const login = async () => {
      const authorizationRequestUrl = await generateCode();
      window.location.href = authorizationRequestUrl.toString();
    };
    useEffect(() => {
      if (
        !customerIsLoading &&
        !customerApiToken &&
        !customerStorefrontApiToken &&
        isReady &&
        !query?.code
      ) {
        login();
      }
    }, [customerIsLoading]);

    if (customerIsLoading || !customerApiToken) {
      return (
        <>
          <Seo noIndex />
          <Spinner minHeight="400px" color="greenish" />
        </>
      );
    }

    return <Component {...props} />;
  };

  hoistNonReactStatics(AuthComponent, Component);

  AuthComponent.displayName = `withAuth(${getDisplayName(Component)})`;

  return AuthComponent;
}
