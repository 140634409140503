import React, { memo, useState } from "react";
import { Box, Icon, Text } from "@storyofams/react-ui";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { Filter as FilterIcon, Sort } from "~components/common/Icons";
import { SortBy } from "~components/products/SortBy";
import { Filters } from "~lib/shopify/sdk";
import { Filter } from "./Filter";
import MobielFilters from "./MobileFilters";
interface FiltersBar {
  productFilters: Filters[];
}
const multiFilters = [
  "filter.p.product_type",
  "filter.p.m.eigenschappen.soort_product",
];
export const FiltersBar: React.FC<FiltersBar> = memo(
  ({ productFilters = [] }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const router = useRouter();
    const { query, sorting, reverse, ...rest } = router.query;
    const counter = rest
      ? Object.values(rest).filter((item) => item).length
      : 0;
    return (
      <>
        <Box
          id="filters"
          maxWidth="maxWidth"
          mx="auto"
          width="100%"
          px={{ all: 2, md: 8, lg: 10 }}
          display={["none", "flex"]}
          alignItems="center"
          flexWrap="wrap"
          height="100%"
          position="relative"
          justifyContent={"space-between"}
        >
          <Box display={"flex"}>
            {productFilters.map((item) => (
              <Filter
                key={item.label}
                id={item.id}
                title={item.label}
                values={item.values}
                isMulti={!multiFilters.includes(item.id)}
              />
            ))}
          </Box>
          <Box position={"relative"}>
            <Icon icon={Sort} ml={2} position="absolute" top="12px" left={0} />
            <SortBy />
          </Box>
        </Box>
        <Box
          display={["flex", "none"]}
          width="100%"
          justifyContent="space-between"
          height={45}
          alignItems="center"
          px="15px"
        >
          <Box
            height={38}
            onClick={() => setIsOpen(true)}
            width="50%"
            justifyContent={"center"}
            display={"flex"}
            border={"1px solid grey200"}
            borderRadius={"md"}
            alignItems={"center"}
            position={"relative"}
            mr="10px"
          >
            <Text mr={1.5} pb="1px">
              Filters
            </Text>
            <Icon icon={FilterIcon}></Icon>
            {!!counter && (
              <Box
                bg={"black"}
                color={"white"}
                border={"1px solid grey200"}
                top={-1}
                width={"22px"}
                right={"-8px"}
                borderRadius={"full"}
                position={"absolute"}
                textAlign={"center"}
                height={"22px"}
              >
                {counter}
              </Box>
            )}
          </Box>
          <Box width={"50%"} position={"relative"}>
            <Icon icon={Sort} ml={2} position="absolute" top="12px" left={0} />
            <SortBy />
          </Box>
        </Box>
        <MobielFilters
          productFilters={productFilters}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </>
    );
  }
);
