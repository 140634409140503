import React, { ReactNode, useEffect, useState } from "react";
import { css, Box, Flex, SystemProps, Icon } from "@storyofams/react-ui";
import { useMeasure } from "react-use";

import { Heading } from "../Heading";
import { Minus, Plus } from "../Icons";

export interface AccordionProps extends SystemProps {
  title: string;
  children: ReactNode | string;
  close?: boolean;
  isOpen?: boolean;
  counter?: number;
}

export const Accordion = ({
  title,
  children,
  close,
  isOpen,
  counter = 0,
  ...props
}: AccordionProps) => {
  const [open, setOpen] = useState(() => (isOpen ? true : false));
  const [ref, { height }] = useMeasure();

  useEffect(() => {
    if (close) setOpen(false);
  }, [close]);
  return (
    <Box
      borderBottom="2px solid"
      borderBottomColor="grey200"
      mt="-1px"
      textAlign="left"
    >
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        /** @ts-ignore this is an indication of whats wrong with the return type of the polymorph */
        onClick={() => setOpen(!open)}
        py={[3, 4]}
        cursor="pointer"
        {...props}
      >
        <Box position={"relative"}>
          <Heading variant="h4" as="h4" color="grey800" fontWeight="medium">
            {title}
          </Heading>
          {!!counter && (
            <Box
              bg={"black"}
              color={"white"}
              border={"1px solid grey200"}
              top={-1}
              width={"22px"}
              right={"-30px"}
              borderRadius={"full"}
              position={"absolute"}
              textAlign={"center"}
              height={"22px"}
            >
              {counter}
            </Box>
          )}
        </Box>

        <Flex>
          <Icon icon={open ? Minus : Plus} pt="5px" />
        </Flex>
      </Flex>
      <Box
        maxHeight={open ? [`${height + 3 * 8}px`, `${height + 4 * 8}px`] : 0}
        transition="max-height ease 0.3s"
        overflow="hidden"
      >
        <Box
          pb={[3, 4]}
          transition="opacity ease 0.25s"
          opacity={open ? 1 : 0}
          ref={ref}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
