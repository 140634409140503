import { useEffect, useState } from "react";
import { Box, Heading, Icon } from "@storyofams/react-ui";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { Accordion } from "~components/common/Accordion";
import { Button } from "~components/common/Button";
import { Cross } from "~components/common/Icons";
// import { SingleOption } from "./FilterBox";
import { FilterBox } from "./FilterBox";

interface MobileFilters {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  productFilters;
}

const MobielFilters: React.FC<MobileFilters> = ({
  isOpen,
  setIsOpen,
  productFilters,
}) => {
  const [isClosed, setIsClosed] = useState<boolean>(false);
  const router = useRouter();
  const posthog = usePostHog();
  useEffect(() => {
    if (isOpen) setIsClosed(false);
  }, [isOpen]);
  const { query, sorting, reverse } = router.query;
  const handleClearButtonClick = () => {
    const route = {
      query: { query, sorting, reverse },
    };
    router.push(route, undefined, { shallow: true });
    setIsClosed(true);
  };
  return (
    <Box
      p={3}
      m={0}
      display={isOpen ? "flex" : "none"}
      flexDirection="column"
      position="fixed"
      width="100%"
      height="100%"
      backgroundColor="#FCF9F7"
      top={0}
      left={0}
      zIndex={999999}
      overflowY="scroll"
    >
      <Icon
        icon={Cross}
        onClick={() => setIsOpen(false)}
        position="absolute"
        top="36px"
        right={3}
        cursor="pointer"
        fontSize={2.5}
      />
      <Box
        as="h1"
        alignSelf="center"
        borderBottom="2px solid grey200"
        width="100%"
        textAlign="center"
        pb={3}
      >
        Filters
      </Box>
      {productFilters.map((item) => {
        const filterId = item.id?.split(".").reverse()[0];
        const currentValue = router.query[filterId];
        const counter = currentValue
          ? String(currentValue).split("+").length
          : 0;
        return (
          <Accordion
            key={item.label}
            title={item.label}
            close={isClosed}
            counter={counter}
          >
            <Box>
              <FilterBox
                isMulti={
                  item.label !== "Soort product" && item.label !== "Producttype"
                }
                items={item.values}
                id={item.id}
              />
            </Box>
          </Accordion>
        );
      })}
      <Box display="flex" mt={5} alignItems="center">
        <Button
          width="50%"
          variant="secondary"
          onClick={handleClearButtonClick}
          mr={1}
        >
          Clear
        </Button>
        <Button ml={1} onClick={() => setIsOpen(false)} width="50%">
          Toepassen
        </Button>
      </Box>
    </Box>
  );
};

export default MobielFilters;
