import { useMemo, useState } from "react";
import { Box, Icon } from "@storyofams/react-ui";
import { ArrowDown } from "~components/common/Icons";
import { FilterBox } from "./FilterBox";
import { useRouter } from "next/router";
interface FilterButton {
  title: string;
  isMulti: boolean;
  values: {
    label: string;
    count: number;
  }[];
  id: string;
}
export const Filter: React.FC<FilterButton> = ({
  title,
  isMulti,
  id,
  values = [],
}) => {
  const { query } = useRouter();
  const filterId = id?.split(".").reverse()[0];
  const currentValue = query[filterId];
  const counter = currentValue ? String(currentValue).split("+").length : 0;
  const [isShow, setShow] = useState<boolean>(false);
  const showDropdown: () => void = () => setShow(true);
  const hideDropdown: () => void = () => setShow(false);
  return (
    <Box position="relative">
      <Box
        border={"1px solid grey200"}
        as="button"
        height={40}
        borderRadius={"md"}
        display="flex"
        alignItems="center"
        onMouseEnter={showDropdown}
        onMouseLeave={hideDropdown}
        mr={"15px"}
        px={"15px"}
        backgroundColor={isShow && "#FCF9F7"}
      >
        {title}
        <Icon icon={ArrowDown} ml={1} width={12} />
      </Box>
      <Box
        mt="1px"
        width={230}
        maxHeight={300}
        p={1}
        backgroundColor="#FCF9F7"
        position="absolute"
        top={"40px"}
        borderRadius={"md"}
        zIndex={3}
        onMouseEnter={showDropdown}
        onMouseLeave={hideDropdown}
        border="1px solid grey200"
        display={!isShow && "none"}
      >
        <FilterBox
          isShow={isShow}
          isMulti={isMulti}
          items={values}
          id={id}
        ></FilterBox>
      </Box>
      {!!counter && (
        <Box
          bg={"black"}
          color={"white"}
          border={"1px solid grey200"}
          top={-1}
          width={"22px"}
          right={"3px"}
          borderRadius={"full"}
          position={"absolute"}
          textAlign={"center"}
          height={"22px"}
        >
          {counter}
        </Box>
      )}
    </Box>
  );
};
