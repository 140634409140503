import { useEffect, useState } from "react";
import { Box, Icon, Input, Text } from "@storyofams/react-ui";
import { useRouter } from "next/router";
import { Checkbox } from "~components/common/Checkbox";
import { Cross } from "~components/common/Icons";
import { Radio } from "~components/common/Radio";
import { usePostHog } from "posthog-js/react";

interface FilterBox {
  items: any;
  isShow?: boolean;
  isMulti?: boolean;
  id: string;
}

export const FilterBox: React.FC<FilterBox> = ({
  items = [],
  isShow = true,
  isMulti = false,
  id,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const filterId = id?.split(".").reverse()[0];
  const posthog = usePostHog();
  useEffect(() => {
    if (!isShow) setInputValue("");
  }, [isShow]);
  const router = useRouter();
  const filterHandler = (event, item, isPicked) => {
    if (router.pathname === "/search")
      posthog.capture("Clicked Filter on search page");
    else
      posthog.capture("Clicked Filter on collection page", {
        page: router.query.handle,
      });
    event.stopPropagation();
    if (isMulti) {
      if (isPicked) {
        const newValue = String(router.query[filterId])
          .split("+")
          .filter((current) => current !== item.label)
          .join("+");
        const route = {
          query: {
            ...router.query,
            [filterId]: newValue,
          },
        };
        router.push(route, undefined, { shallow: true });
      } else {
        const currentValue = router.query[filterId];
        const route = {
          query: {
            ...router.query,
            [filterId]: !currentValue
              ? item.label
              : currentValue + "+" + item.label,
          },
        };
        router.push(route, undefined, { shallow: true });
      }
    } else {
      const currentValue = router.query[filterId] === item.label;
      const route = {
        query: {
          ...router.query,
          [filterId]: !currentValue ? item.label : "",
        },
      };
      router.push(route, undefined, { shallow: true });
    }
  };
  return (
    <>
      {isShow && (
        <Box position="relative" mb={2} mt={1}>
          <Input
            borderRadius="5px"
            placeholder={"Zoek binnen filter"}
            border="1px solid grey400"
            onChange={(event) => {
              setInputValue(event.currentTarget.value);
            }}
            autoFocus
            value={inputValue}
          />
          <Icon
            icon={Cross}
            position="absolute"
            top={1.5}
            right={1.5}
            cursor="pointer"
            onClick={() => {
              setInputValue("");
            }}
          />
        </Box>
      )}
      <Box overflowY="auto" overflowX="hidden" maxHeight={["100%", "220px"]}>
        {items.length ? (
          items
            .filter((item) =>
              item.label.toLowerCase().includes(inputValue.toLowerCase())
            )
            .map((item) => {
              const isPicked = String(router.query[filterId])
                .split("+")
                .includes(item.label);
              return (
                <Box
                  display="flex"
                  height={30}
                  mt="5px"
                  key={item.label}
                  onClick={(event) => {
                    filterHandler(event, item, isPicked);
                  }}
                  css={{ textTransform: "capitalize" }}
                  cursor="pointer"
                  alignItems="center"
                >
                  {isMulti ? (
                    <Checkbox checked={isPicked} pt="1px" />
                  ) : (
                    <Radio
                      value={""}
                      label={""}
                      checked={isPicked}
                      disabled={false}
                      readOnly
                    />
                  )}
                  <Text overflow="hidden" whiteSpace="nowrap">
                    {item.label.replace(/_/g, " ")}
                  </Text>
                </Box>
              );
            })
        ) : (
          <Text>Geen Opties</Text>
        )}
      </Box>
    </>
  );
};
